import React, { useEffect, useState } from 'react'
import NavBar from '../../layout/navbar/navbar'
import CompanyInformation from './company-information'
// import ProductsServices from './products-services'
import ProductGallery from './product-gallery'
import ReasonsForShutdown from './reasons-for-shutdown'
import DirectCompetitors from './direct-competitors.js'
import Investors from './investors.js'
import Management from './management.js'
import Financials from './financials.js'
import PitchDecksView from './pitch-decks-view'
import DataRoomView from '../data-room/data-room-view'
import { Tabs, Tab, Accordion, Card, Button, Spinner, Alert } from 'react-bootstrap'
import { S3Media } from '../../third-party-services'
import { FaChevronDown, FaChevronLeft, FaEdit, FaEllipsisH } from 'react-icons/fa'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { useParams } from 'react-router'
import { useRealmApp } from '../../RealmApp';
import { useHistory, useLocation } from 'react-router-dom'
import './company.css'

const CompanyPage = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // initialise history and location
    const history = useHistory();
    const location = useLocation();

    // set state from url hash
    const [selectedTab, setSelectedTab] = useState(location.hash.substring(1));

    const handleSelect = (e) => {
        // set state
        setSelectedTab(e);
        // update url
        history.replace({
            hash: `${e}`
        });
    }

    const { currentUser, logInAnonymous, getUserTier } = useRealmApp();

    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    const { getCompanyDetail } = currentUser.functions;

    // get slug
    const { slug } = useParams();

    // company object
    const [company, setCompany] = useState();

    // fetch company data
    useEffect(async () => {

        setAlert(true);

        let response = await getCompanyDetail(slug)
            .then(function (response) {
                setAlert(false);
                return response;
            })
            .catch(function (error) {
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}><a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">Contact us</a>.</p>
                </div>);
            });

        setCompany(response);

        window.scrollTo(0, 0);
    }, []);

    const [changeArrow, setChangeArrow] = useState(
        {
            companyInformation: true,
            productsAndServices: false,
            productGallery: false,
            reasonsForShutdown: true,
            directCompetitors: true,
            investors: true,
            management: true,
            financials: true
        }
    );

    function changeCompanyInformation() {
        setChangeArrow({ companyInformation: !changeArrow.companyInformation });
    }

    // function changeProductsAndServices() {
    //     setChangeArrow({ productsAndServices: !changeArrow.productsAndServices });
    // }

    function changeProductGallery() {
        setChangeArrow({ productGallery: !changeArrow.productGallery });
    }

    function changeReasonsForShutdown() {
        setChangeArrow({ reasonsForShutdown: !changeArrow.reasonsForShutdown });
    }

    function changeDirectCompetitors() {
        setChangeArrow({ directCompetitors: !changeArrow.directCompetitors });
    }

    function changeInvestors() {
        setChangeArrow({ investors: !changeArrow.investors });
    }

    function changeManagement() {
        setChangeArrow({ management: !changeArrow.management });
    }

    function changeFinancials() {
        setChangeArrow({ financials: !changeArrow.financials });
    }

    const collection = "companies";

    return (
        <>
            <NavBar />

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* <div className="companyeditdiv">
                <Link to={`/data/edit/${collection}/${slug}`} className="companyedit">
                    <FaEdit /><span style={{ marginLeft: "10px", fontFamily: "AileronRegular", fontSize: "14px" }}>Update information</span>
                </Link>
            </div> */}

            <div id="company">
                <div className="companycontent">

                    <div className="companyheader">
                        <div className="companylogodiv">
                            <img src={S3Media + 'assets/photos/logo/' + company?.logo} alt={company?.tradeName} className="companylogo" />
                        </div>
                        <div className="companyheaderright">
                            <h3>{company?.tradeName}</h3>
                            <p>{company?.description}</p>
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" activeKey={selectedTab ? selectedTab : "profile"} onSelect={(e) => handleSelect(e)}>

                            <Tab eventKey="profile" title="Profile">
                                <Accordion className="companyaccordion" defaultActiveKey="companyinformation">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="companyinformation" onClick={changeCompanyInformation} className="companycardheader">
                                            <div className="companyarrowicon">
                                                {changeArrow.companyInformation
                                                    ? (<FaChevronDown />)
                                                    : (<FaChevronLeft />)
                                                }
                                            </div>

                                            <div className="companytitlecard">
                                                Company Information
                                            </div>

                                            <div className="companyellipsisicon">
                                                <FaEllipsisH />
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="companyinformation">
                                            <CompanyInformation
                                                key={company?.slug}
                                                description={company?.f_description}
                                                legalName={company?.legalName}
                                                formerLegalName={company?.former_legalName}
                                                entityType={company?.entityType}
                                                stageOfDevelopment={company?.stageOfDevelopment}
                                                businessModelType={company?.businessModelType}
                                                defensibilityMoat={company?.defensibilityMoat}
                                                foundedDate={company?.details.date?.started}
                                                shutdownDate={company?.details.date?.ended}
                                                operationalDuration={company?.details.year?.lifespan}
                                                lastFinancingRound={company?.details.lastFinancingRound}
                                                lastFinancingDate={company?.details.lastFinancingDate}
                                                totalRaisedCurrency={company?.details.totalInvestment?.currency}
                                                totalRaised={company?.details.totalInvestment?.total}
                                                totalRaisedSymbol={company?.details.totalInvestment?.symbol}
                                                noOfFundingRounds={company?.details.numOfFundingRounds}
                                                investors={company?.investors}
                                                noOfEmployeesMin={company?.details.numOfEmployees?.min}
                                                noOfEmployeesMax={company?.details.numOfEmployees?.max}
                                                city={company?.details.location?.city}
                                                state={company?.details.location?.state}
                                                country={company?.details.location?.country}
                                                industry={company?.industry}
                                                categories={company?.categories}
                                            />
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                {/* <Accordion style={{ marginTop: "10px" }}>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="productsandservices" onClick={changeProductsAndServices} className="companycardheader">
                                            <div className="companyarrowicon">
                                                {changeArrow.productsAndServices
                                                    ? (<FaChevronDown />)
                                                    : (<FaChevronLeft />)
                                                }
                                            </div>

                                            <div className="companytitlecard">
                                                Products and Services
                                            </div>

                                            <div className="companyellipsisicon">
                                                <FaEllipsisH />
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="productsandservices">
                                            <ProductsServices
                                                key={company?.slug}
                                                products={company?.details.products} />
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion> */}

                                <Accordion style={{ marginTop: "10px" }}>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="productgallery" onClick={changeProductGallery} className="companycardheader">
                                            <div className="companyarrowicon">
                                                {changeArrow.productGallery
                                                    ? (<FaChevronDown />)
                                                    : (<FaChevronLeft />)
                                                }
                                            </div>

                                            <div className="companytitlecard">
                                                Product Gallery
                                            </div>

                                            <div className="companyellipsisicon">
                                                <FaEllipsisH />
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="productgallery">
                                            <ProductGallery
                                                key={company?.slug}
                                                products={company?.products}
                                                name={company?.tradeName} />
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Tab>

                            {company?.reasonsForFailure
                                ? (<Tab eventKey="postmortem" title="Postmortem">
                                    <Accordion className="companyaccordion" defaultActiveKey="reasonsforshutdown">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="reasonsforshutdown" onClick={changeReasonsForShutdown} className="companycardheader">
                                                <div className="companyarrowicon">
                                                    {changeArrow.reasonsForShutdown
                                                        ? (<FaChevronDown />)
                                                        : (<FaChevronLeft />)
                                                    }
                                                </div>

                                                <div className="companytitlecard">
                                                    Reasons for Shutdown
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>

                                            <Accordion.Collapse eventKey="reasonsforshutdown">
                                                <ReasonsForShutdown
                                                    key={company?.slug}
                                                    reasonForShutdown={company?.reasonsForFailure} />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Tab>)
                                : ""
                            }

                            <Tab eventKey="competition" title="Competition">
                                <Accordion className="companyaccordion" defaultActiveKey="directcompetitors">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="directcompetitors" onClick={changeDirectCompetitors} className="companycardheader">
                                            <div className="companyarrowicon">
                                                {changeArrow.directCompetitors
                                                    ? (<FaChevronDown />)
                                                    : (<FaChevronLeft />)
                                                }
                                            </div>

                                            <div className="companytitlecard">
                                                Direct Competitors and Similar Companies
                                            </div>

                                            <div className="companyellipsisicon">
                                                <FaEllipsisH />
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="directcompetitors">
                                            <DirectCompetitors
                                                key={company?.slug}
                                                directCompetitors={company?.competitors}
                                                similarEntities={company?.similarEntities}
                                            />
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Tab>

                            <Tab eventKey="investors" title="Investors">
                                <Accordion className="companyaccordion" defaultActiveKey="investors">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="investors" onClick={changeInvestors} className="companycardheader">
                                            <div className="companyarrowicon">
                                                {changeArrow.investors
                                                    ? (<FaChevronDown />)
                                                    : (<FaChevronLeft />)
                                                }
                                            </div>

                                            <div className="companytitlecard">
                                                Investors
                                            </div>

                                            <div className="companyellipsisicon">
                                                <FaEllipsisH />
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="investors">
                                            <Investors
                                                key={company?.slug}
                                                investors={company?.investors}
                                            />
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Tab>

                            <Tab eventKey="management" title="Management">
                                <Accordion className="companyaccordion" defaultActiveKey="management">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="management" onClick={changeManagement} className="companycardheader">
                                            <div className="companyarrowicon">
                                                {changeArrow.management
                                                    ? (<FaChevronDown />)
                                                    : (<FaChevronLeft />)
                                                }
                                            </div>

                                            <div className="companytitlecard">
                                                Leadership
                                            </div>

                                            <div className="companyellipsisicon">
                                                <FaEllipsisH />
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="management">
                                            <Management
                                                key={company?.slug}
                                                management={company?.management}
                                            />
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Tab>

                            <Tab eventKey="financials" title="Financials">
                                <Accordion className="companyaccordion" defaultActiveKey="financials">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="financials" onClick={changeFinancials} className="companycardheader">
                                            <div className="companyarrowicon">
                                                {changeArrow.financials
                                                    ? (<FaChevronDown />)
                                                    : (<FaChevronLeft />)
                                                }
                                            </div>

                                            <div className="companytitlecard">
                                                Funding
                                            </div>

                                            <div className="companyellipsisicon">
                                                <FaEllipsisH />
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="financials">
                                            <Financials
                                                key={company?.slug}
                                                financials={company?.raised}
                                            />
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Tab>

                            {/* <Tab eventKey="filings" title="Filings"></Tab> */}

                            <Tab eventKey="media" title="Media" disabled></Tab>

                            {
                                !getUserTier() || getUserTier() < 2
                                    ? ""
                                    : <Tab eventKey="data-room" title="Data Room">
                                        {!company?.pitchdecks || company?.pitchdecks.length < 1
                                            ? ""
                                            : <PitchDecksView
                                                key={company?.slug}
                                                name={company?.tradeName}
                                                pitchdecks={company?.pitchdecks}
                                            />
                                        }

                                        <DataRoomView />
                                    </Tab>
                            }
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyPage