import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import './alert-banner.css'

function AlertBanner(props) {

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY === 0) {
                setScroll(false);
            }
            else {
                setScroll(true);
            }
        });
    }, []);

    return (
        <>
            <Alert onClose={props.closeAlert} dismissible className={scroll ? "alertBannerSticky alertBannerComponent" : "alertBannerComponent"} id="alertBannerComponent">
                <p className="alertBannerText"><button className="customButton customButtonStyle faqButton">
                    <span>ANNOUNCEMENT</span>
                </button>Due to the deprecation and end-of-life of a few third-party services, we're upgrading our user interface for better user experience and enhancing the security of our backend services.</p>
            </Alert>
        </>
    )
}

export default AlertBanner